<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="toAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->
        <v-input label="活动名称" v-model="searchParam.activityName" />
        <v-select
          clearable
          :options="activityStatus"
          v-model="searchParam.activityStatus"
          @change="$refs.list.search()"
          label="活动状态"
        />
        <v-select
          clearable
          :options="activityTypeOptions"
          v-model="searchParam.activityType"
          @change="$refs.list.search()"
          label="活动发起"
        />
        <v-select
          clearable
          :options="activityCategoryOptions"
          v-model="searchParam.activityCategory"
          @change="$refs.list.search()"
          label="活动类型"
        />
        <v-select
          clearable
          :options="auditStatusOptionsAll"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="活动二维码"
          type="text"
          v-if="
            scope.row.activityQrCodeUrl &&
            scope.row.activityQrCodeUrl.length > 0
          "
          @click="showACQrCode(scope.row.activityQrCodeUrl)"
        />
        <v-button
          text="签到二维码"
          type="text"
          @click="showQrCode(scope.row)"
        />

        <v-button
          text="活动回顾"
          type="text"
          v-if="scope.row.activityStatus == 5"
          @click="toEditFeedback(scope.row)"
        />
        <v-button
          text="删除"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toDelete(scope.row)"
        />
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <!-- <v-button
          text="审核"
          type="text"
          v-if="scope.row.activityType == 0 && scope.row.auditStatus == 0"
          @click="toAudit(scope.row)"
        /> -->
        <v-button
          text="取消"
          type="text"
          v-if="scope.row.activityStatus != 6"
          @click="toCancel(scope.row)"
        />
        <v-button text="参与人数" type="text" @click="toMoPeople(scope.row)" />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="auditDialogFormVisible"
      width="50%"
      @confirm="doAudit"
    >
      <el-form label-width="100px">
        <el-form-item label="活动标题">
          <v-input v-model="tempObj.activityName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动地址">
          <v-input v-model="tempObj.activityAddress" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动图片">
          <v-upload :imgUrls.sync="tempObj.activityPicture" disabled />
        </el-form-item>
        <el-form-item label="发布者">
          <v-input v-model="tempObj.publisherName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="发布时间">
          <v-input v-model="tempObj.publishTime" :width="400" disabled />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select
            :options="auditStatusOptions"
            v-model="tempObj.auditStatus"
          />
        </el-form-item>
        <el-form-item label="奖励积分" v-if="tempObj.auditStatus === '1'">
          <v-input v-model="tempObj.bonusPoints" type="number" />
        </el-form-item>
        <el-form-item label="备注信息" v-if="tempObj.auditStatus === '1'">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
    <v-dialog
      title="场次积分二维码"
      class="realNameAuditForm-addAddress"
      v-model="isdialog"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="关闭"
    >
      <div class="html2canvasRef" ref="html2canvasRef"></div>
      <div class="img" v-for="(item, index) in imgList" :key="index">
        <img :src="item.pointsQrCodeUrl" alt="" />
        <div class="h3">{{ activityName }}</div>
        <div class="downloadBtn">
          <v-button text="下载" @click="downLoad(item)"></v-button>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      title="输入参与人数"
      v-model="isPeopledialog"
      @confirm="confirmPeople"
      cancelTxt="取消"
      sureTxt="修改"
    >
      <div class="people">
        <v-input label="参与人数" v-model="applyUserCount" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getActivityListUrl,
  activityCancel,
  activityAudit,
  delUrl,
  applyUserCountUrl,
} from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { activityStatus, isTopMap } from "./map.js";
// import { donateAudit } from "@/views/neighborhood/donateManage/api";
import html2canvas from "html2canvas"; //生成图片
export default {
  name: "applyActivity",
  data() {
    return {
      scheduleList: [],
      auditDialogFormVisible: false,
      isdialog: false,
      imgList: [],
      tempObj: {
        activityId: null,
        activityName: null,
        activityAddress: null,
        activityPicture: null,
        publisherName: null,
        publishTime: null,
        publisherPic: null,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 0,
      },
      isPeopledialog: false,
      activityId: "",
      activityName: "", //活动标题
      applyUserCount: "",
      auditStatusOptions: [
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      auditStatusOptionsAll: [
        { value: "0", label: "待审核" },
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      activityTypeOptions: [
        { value: "0", label: "业主发起" },
        { value: "1", label: "管理后台" },
      ],
      activityCategoryOptions: [
        { value: 1, label: "热门活动" },
        { value: 20, label: "志愿服务" },
        { value: 19, label: "幸福学堂" },
      ],
      activityStatus: activityStatus,
      searchParam: {
        communityId: null,
        activityName: null,
        activityStatus: null,
        auditStatus: null,
        activityType: null,
        activityCategories: "1,19,20",
      },
      tableUrl: getActivityListUrl,
      urlImg: "",
      headers: [
        // {
        //   prop: "communityName",
        //   label: "园区名称",
        // },
        // {
        //   prop: "activityId",
        //   label: "活动Id",
        // },
        {
          prop: "activityName",
          label: "活动名称",
        },
        {
          prop: "activityAddress",
          label: "活动地点",
        },
        {
          prop: "activityPicture",
          label: "活动图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "limitCount",
          label: "限制人数",
        },
        {
          prop: "applyUserCount",
          label: "活动参与人数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row.applyUserCount,
              cb: () => this.toApplicationsList(row),
            }),
        },
        {
          prop: "activityStatus",
          label: "活动状态",
          formatter: (row, prop) => {
            for (let i = 0; i < activityStatus.length; i++) {
              if (row.activityStatus === Number(activityStatus[i].value)) {
                return activityStatus[i].label;
              }
            }
          },
        },
        {
          prop: "applyStartDate",
          label: "报名开始时间",
        },
        {
          prop: "applyEndDate",
          label: "报名结束时间",
        },
        {
          prop: "startDate",
          label: "活动开始时间",
        },
        {
          prop: "endDate",
          label: "活动结束时间",
        },
        {
          prop: "是否置顶",
          label: "是否置顶",
          formatter: (row, prop) => {
            if (row.isRecommend == 1) {
              return isTopMap[row.isRecommend];
            } else {
              return "否";
            }
          },
        },
      ],
    };
  },
  destroyed() {},
  components: {},
  mounted() {},
  deactivated() {
    this.$store.commit("app/setIsKeepAlive", false);
  },
  activated() {
    if (this.isKeepAlive) {
      this.$refs.list.search();
    }
  },
  computed: {
    ...mapState("app", ["isKeepAlive"]),
  },
  methods: {
    async downLoad(item) {
      let _this = this;
      console.log(this.$refs.html2canvasRef, "99");
      this.$nextTick(async () => {
        this.$refs.html2canvasRef.innerHTML = `
      <div class="box">
          <img src="" alt="" id="myImg" />
          <div class="title" style="text-align:center" id="title"></div>
        </div>
      `;
        this.$nextTick(async () => {
          this.docuHtml(item, this.activityName);
          await html2canvas(this.$refs.html2canvasRef, {
            backgroundColor: "#fff",
            useCORS: true,
            scale: 1,
          }).then((canvas) => {
            let url = canvas.toDataURL("image/png");
            let imgObj = {
              url: url,
              name: _this.activityName,
            };
            this.toload(imgObj);
          });
        });
      });
    },
    toload(item) {
      var blob = this.dataURLtoBlob(item.url);
      const elink = document.createElement("a");
      elink.download = `${item.name}.png`;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      document.body.removeChild(elink);
    },
    toDelete(data) {
      this.$confirm("确定要删除？", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${delUrl}`, null, {
              params: {
                activityId: data.activityId,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("取消成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    confirmPeople() {
      if (!this.applyUserCount) {
        this.$message.error("请输入参与人数");
        return;
      }
      this.$axios
        .post(`${applyUserCountUrl}`, null, {
          params: {
            activityId: this.activityId,
            applyUserCount: this.applyUserCount,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.isPeopledialog = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    toMoPeople(data) {
      this.isPeopledialog = true;
      this.activityId = data.activityId;
      this.$refs.html2canvasRef.innerHTML = ``;
    },
    confirm() {
      this.isdialog = false;
    },
    toAudit(data) {
      this.auditDialogFormVisible = true;
      this.tempObj = {
        activityId: data.activityId,
        activityName: data.activityName,
        activityAddress: data.activityAddress,
        activityPicture: data.activityPicture,
        publisherName: data.publisherName,
        publishTime: data.publishTime,
        publisherPic: data.publisherPic,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 0,
      };
    },

    doAudit() {
      if (!this.tempObj.auditStatus) {
        this.$message.error("请选择审核结果！");
        return;
      }
      if (this.tempObj.bonusPoints && this.tempObj.bonusPoints < 0) {
        this.$message.error("奖励积分不能小于0！");
        return;
      }
      this.$axios
        .post(`${activityAudit}`, null, {
          params: {
            activityId: this.tempObj.activityId,
            auditStatus: this.tempObj.auditStatus,
            auditRemarks: this.tempObj.auditRemarks,
            bonusPoints: this.tempObj.bonusPoints,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showACQrCode(url) {
      window.open(url);
    },

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    },
    docuHtml(line, activityName) {
      document.getElementById("myImg").src = line.pointsQrCodeUrl;
      document.getElementById("title").innerHTML = activityName;
    },
    async showQrCode(row) {
      this.isdialog = true;
      this.imgList = [];
      this.activityName = "";
      this.activityName = row.activityName;
      this.imgList = row.scheduleList;
      // if (row.scheduleList) {
      //   this.scheduleList = row.scheduleList;
      //   console.log(this.scheduleList, "测试");
      //   this.activityName = row.activityName;
      //   this.isdialog = true;
      // } else {
      //   window.open(row.pointsQrCodeUrl);
      // }
    },
    toAdd() {
      this.$router.push({
        name: "addActivity",
      });
    },
    toEditFeedback(data) {
      this.$router.push({
        name: "activityFeedback",
        query: { activityId: data.activityId, communityId: data.communityId },
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "addActivity",
        query: { activityId: data.activityId, communityId: data.communityId },
      });
    },
    toCancel(data) {
      let message = "确定要取消该活动 " + data.activityName;
      if (data.applyUserCount > 0) {
        message = "当前已经报名 " + data.applyUserCount + ", " + message;
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${activityCancel}`, null, {
              params: {
                activityId: data.activityId,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("取消成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    toApplicationsList(data) {
      this.$router.push({
        name: "activityApplicants",
        query: {
          id: data.activityId,
          cid: data.communityId,
          name: data.activityName,
          type: data.activityCategory,
          scheduleId: data.scheduleList ? data.scheduleList[0].id : "",
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .h3 {
    text-align: center;
    margin: 20px auto;
  }
  .realNameAuditForm-addAddress {
    /deep/ .el-dialog__body {
      max-height: 600px;
      overflow: auto;
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .html2canvasRef {
        width: 400px;
        position: absolute;
        top: -99999px;
        .box {
          img {
            width: 100%;
          }
          #title {
            text-align: center;
            margin-top: 20px;
            font-size: 18px;
          }
        }
      }
    }
  }

  .downloadBtn {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
